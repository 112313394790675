<template>
  <div style="max-width: 100%; min-height: 750px">
    <!--基本信息-->
    <div class="bgc_w pt-4 pb-4">
      <div>
        <div class="title">
          <img
            src="@/assets/img/head/icon.png"
            style="margin-bottom: 5px; margin-right: 5px"
          />
          基本信息
        </div>
        <div class="mt-4 mb-2 content">
          <el-row>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">姓名 ：</div>
                <div class="col color-9a">
                  {{ userInfo.first_name || "--" }}
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">手机号码 ：</div>
                <div class="col color-9a">
                  {{ userInfo.phone_number || "--" }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-3">
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">职位名称 ：</div>
                <div
                  style="
                    width: 270px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 15px;
                  "
                  :title="userInfo.position"
                  class="color-9a"
                >
                  {{ userInfo.position || "--" }}
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">工作单位 ：</div>
                <div
                  class="color-9a"
                  style="
                    width: 270px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-left: 15px;
                  "
                  :title="userInfo.company_name"
                >
                  {{ userInfo.company_name || "--" }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-3">
            <el-col :span="10">
              <div class="row">
                <div class="row align-items-center">
                  <div class="list-t text-right color-4d">用户邮箱 ：</div>
                  <el-input
                    v-model="userInfo.email"
                    type="email"
                    size="small"
                    placeholder="请输入邮箱号"
                    style="width: 200px !important"
                  ></el-input>
                  <a style="margin-left: 5px; color: #3a85fd" @click="bound"
                    >绑定</a
                  >
                </div>
                <div
                  v-if="flag"
                  style="width: 400px; margin-top: 2px; text-align: center"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    style="width: 93px; margin-left: 40px"
                    @click="updateEmail"
                    >确定</el-button
                  >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    style="margin-left: 10px; width: 93px"
                    @click="cancel"
                    >取消</el-button
                  >
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">账户类型 ：</div>
                <div class="col color-9a">
                  {{
                    userInfo.UserInfoExSer.account_type == 0
                      ? "普通用户"
                      : "专业用户"
                  }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-3">
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">生效日期 ：</div>
                <div class="col color-9a">
                  {{
                    userInfo.UserInfoExSer.start_validity.substring(0, 10) ||
                      "--"
                  }}
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">到期日期 ：</div>
                <div class="col color-9a">
                  {{
                    userInfo.UserInfoExSer.end_validity.substring(0, 10) || "--"
                  }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row class="mt-3">
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">每日查询上限 ：</div>
                <div class="col color-9a">
                  {{
                    userInfo.UserInfoExSer.daily_query_count +
                      " / " +
                      userInfo.UserInfoExSer.daily_query_cap || "--"
                  }}
                </div>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="row">
                <div class="list-t text-right color-4d">剩余查询次数 ：</div>
                <div class="col color-9a">
                  {{ userInfo.UserInfoExSer.points_available || "--" }}
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userBasicInfo, UpdateUserEmail } from "@/api";
export default {
  data() {
    return {
      flag: false,
      user_email: "",
      userInfo: {},
      active_date: "",
      expire_date: "",
      role_name: "",
      points_available: "",
      points_remaining: "",
      daily_query_cap: "",
      daily_query_count: "",
      start_validity: "",
      end_validity: "",
      account_type: "",
    };
  },
  methods: {
    bound() {
      this.flag = true;
    },
    cancel() {
      this.flag = false;
    },
    updateEmail() {
      // console.log(this.user_email);
      var config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "Bearer " + this.$store.state.isLogin.loginStore.auth_token,
        },
      };
      var re = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
      if (re.test(this.userInfo.email)) {
        // this.$message.success("")
        UpdateUserEmail(
          {
            email: this.userInfo.email,
          },
          config
        ).then((res) => {
          this.$Message.success(res.data.Message);
          this.queryUserInfo();
          this.flag = false;
        });
      } else {
        this.$Message.error("请输入正确邮箱格式");
      }
    },
    queryUserInfo() {
      userBasicInfo().then(
        (res) => {
          this.userInfo = res.data;

          // this.role_name = this.userInfo.RoleNameSer.role_name;
          // this.points_available = this.userInfo.UserInfoExSer.points_available;
          // this.points_remaining = this.userInfo.UserInfoExSer.points_remaining;
          // this.daily_query_cap = this.userInfo.UserInfoExSer.daily_query_cap;
          // this.daily_query_count = this.userInfo.UserInfoExSer.daily_query_count;
          // this.account_type = res.data.UserInfoExSer.account_type;
        },
        (res) => {
          console.error("userBasicInfo", res);
        }
      );
    },
  },
  mounted() {
    this.queryUserInfo();
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: 550;
  background: #fff;
  padding-left: 20px;
}

.content {
  padding-left: 30px;
  padding-right: 30px;
}

.list-t {
  width: 120px;
}

.h34px {
  height: 34px;
  line-height: 34px;
}

.bind:hover {
  color: #fff !important;
  background-color: #1172fe;
}

@media (max-width: 460px) {
  .mb_wechat {
    width: 41%;
    line-height: 31px;
  }

  .h34px {
    width: 50%;
    font-size: 13px;
  }

  .mb_bang {
    font-size: 13px;
    width: 100px;
    height: 30px;
    line-height: 30px;
    margin-top: 5px;
  }
}
</style>
